import { compact, get, isEmpty, omit, omitBy, values, flatten, uniqBy, isEqual } from 'lodash';
import { getCoreUrl, getMakeModelVsMakeModelUrl } from 'site-modules/shared/utils/core-link-constructor';
import { srpLinkBuilderWithParamsConversion } from 'site-modules/shared/utils/srp-link-constructor';
import { getCurrentYear } from 'site-modules/shared/utils/time-util';
import { PUB_STATES } from 'client/constants/pub-states';
import { INVENTORY_TYPES_LOWERCASE } from 'client/constants/inventory-types';

const WAGON = 'Wagon';
const WAGON_LOWERCASE = 'wagon';
const FULL_SIZE_VAN = 'Full Size Van';
const ELECTRIC = 'Electric';
const HYBRID = 'Hybrid';
const ELECTRIC_ENGINE_TYPES = [ELECTRIC, HYBRID];

function getMMTData({ struct }) {
  return flatten(
    [get(struct, 'makeModelTrim'), get(struct, 'makeModel'), get(struct, 'makes')].filter(el => !isEmpty(el))
  );
}

export function getCompareLink({ fastMatcherData }) {
  const { struct } = fastMatcherData;
  const data = getMMTData({ struct });

  if (data.length === 2 && data.every(vehicle => vehicle.make && vehicle.model)) {
    const vehicles = data.map(facets => ({
      make: get(facets, 'make.value'),
      model: get(facets, 'model.value', '').split('|')[1],
      title: compact([get(facets, 'make.name', ''), get(facets, 'model.name')]).join(' '),
    }));

    return {
      title: `${vehicles[0].title} vs. ${vehicles[1].title}`,
      url: getMakeModelVsMakeModelUrl(vehicles[0], vehicles[1]),
      selectionType: 'compare models',
    };
  }

  return null;
}

function parseJSON(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

function getBodyTypes(facetsByName) {
  return facetsByName?.find(({ type }) => type === 'bodyType')?.values.map(({ name }) => name);
}

function getEngineTypes(facetsByName) {
  return facetsByName?.find(({ type }) => type === 'engineType')?.values.map(({ name }) => name);
}

function getSrpSelectionType({ options }) {
  const SELECTION_TYPES_AVAILABLE = ['year', 'make', 'model', 'trim', 'bodyType'];
  const selectionType = flatten([
    options.inventorytype.includes(INVENTORY_TYPES_LOWERCASE.NEW)
      ? INVENTORY_TYPES_LOWERCASE.NEW
      : INVENTORY_TYPES_LOWERCASE.USED,
    compact(SELECTION_TYPES_AVAILABLE.map(selType => (options[selType] ? selType : null))),
  ])
    .join(' ')
    .replace('bodyType', 'type');

  return `${selectionType} for sale`;
}

export function getSrpLink({ fastMatcherData, types }) {
  const { struct, delta } = fastMatcherData;
  const parsedDelta = parseJSON(delta);

  if (!delta) {
    return null;
  }

  const deltaInventoryType = get(parsedDelta, 'inventoryType', []);
  const deltaInventoryTypeFiltered = deltaInventoryType.filter(inventoryType =>
    types.includes(inventoryType.toLowerCase())
  );
  const inventoryTypes = isEmpty(deltaInventoryType) ? types : deltaInventoryTypeFiltered;
  if (isEmpty(inventoryTypes)) {
    return null;
  }

  const title = new Set();
  const SPECIAL_FIELDS = ['make', 'model', 'trim', 'inventorytype'];
  let options = { ...omit(parsedDelta, [...SPECIAL_FIELDS, 'inventoryType']) };
  const data = getMMTData({ struct });

  if (options.bodyType) {
    options.bodyTypeName = getBodyTypes(struct.facetsByName);
  }

  if (options.engineType) {
    options.engineTypeName = getEngineTypes(struct.facetsByName);
  }

  const joinedEngineTypeNames = options.engineTypeName?.join(', ');
  const joinedBodyTypeNames = options.bodyTypeName?.join(', ');

  if (!isEmpty(data)) {
    const filters = {
      make: new Set(),
      model: new Set(),
      trim: new Set(),
      inventorytype: new Set(),
    };

    data.forEach(facets => {
      inventoryTypes.forEach(type => {
        if (get(facets, `inventoryCount[${type}]`)) {
          filters.inventorytype.add(type);
          filters.make.add(get(facets, 'make.value'));
          filters.model.add(get(facets, 'model.value'));
          filters.trim.add(get(facets, 'trim.value'));
          title.add(
            compact([
              options.year,
              get(facets, 'make.name', ''),
              get(facets, 'model.name', ''),
              get(facets, 'trim.name', ''),
              joinedEngineTypeNames,
              joinedBodyTypeNames,
            ]).join(' ')
          );
        }
      });
    });
    options = {
      ...options,
      ...SPECIAL_FIELDS.reduce(
        (res, filterName) => ({ ...res, [filterName]: compact(Array.from(filters[filterName])).join(',') }),
        {}
      ),
    };
  } else {
    options = { ...options, inventorytype: inventoryTypes };
    title.add(compact([options.year, joinedEngineTypeNames, joinedBodyTypeNames]).join(' '));
  }

  const selectionType = getSrpSelectionType({ options });

  if (isEmpty(omitBy(options, val => isEmpty(val))) || !options.inventorytype) {
    return null;
  }

  return {
    title: `${
      types.includes(INVENTORY_TYPES_LOWERCASE.NEW) ? INVENTORY_TYPES_LOWERCASE.NEW : INVENTORY_TYPES_LOWERCASE.USED
    } ${Array.from(title).join(' and ')} for sale`,
    url: srpLinkBuilderWithParamsConversion(options),
    selectionType,
  };
}

function getYear({ publicationStates }) {
  const currentYear = getCurrentYear();
  const availableYears = flatten(values(publicationStates));

  if (availableYears.includes(currentYear)) {
    return currentYear;
  }

  return availableYears.length ? Math.max(...availableYears) : currentYear;
}

export function getPricingReviewsLinks({ fastMatcherData }) {
  const { struct, delta } = fastMatcherData;
  const data = getMMTData({ struct });
  const deltaInventoryType = get(parseJSON(delta), 'inventoryType');
  const isNewInventory = deltaInventoryType?.some(inventoryType => inventoryType === INVENTORY_TYPES_LOWERCASE.NEW);

  if (deltaInventoryType && !isNewInventory) {
    return null;
  }

  return uniqBy(
    compact(
      data.map(facets => {
        const makeSlug = get(facets, 'make.value', '');
        const modelSlug = get(facets, 'model.value', '').split('|')[1];
        const publicationStates = get(facets, 'publicationState', {});

        if (!modelSlug || isEqual(Object.keys(publicationStates), [PUB_STATES.USED])) return null;

        const year = parseInt(getYear({ publicationStates }), 10);
        const defaultYear = parseInt(getCurrentYear(), 10);

        return {
          title: compact([get(facets, 'make.name', ''), get(facets, 'model.name')]).join(' '),
          url: getCoreUrl({
            makeSlug,
            modelSlug,
            year,
            defaultYear,
          }),
          makeSlug,
          modelSlug,
          year,
          selectionType: `${year >= defaultYear ? 'new' : 'used'} review`,
        };
      })
    ),
    'title'
  );
}

export function getResearchLinks({ fastMatcherData }) {
  const { struct } = fastMatcherData;
  const data = getMMTData({ struct });

  if (getCompareLink({ fastMatcherData })) {
    return null;
  }

  return uniqBy(
    data.map(facets => ({
      title: `all ${get(facets, 'make.name', '')} models`,
      url: `/${get(facets, 'make.value')}/`,
      selectionType: 'make page',
    })),
    'title'
  );
}

export function getResearchTypeLinks({ fastMatcherData }) {
  const { struct } = fastMatcherData;
  const bodyTypes = getBodyTypes(struct.facetsByName)?.filter(bodyType => bodyType !== FULL_SIZE_VAN);
  const engineTypes = getEngineTypes(struct.facetsByName)?.filter(engineType =>
    ELECTRIC_ENGINE_TYPES.includes(engineType)
  );

  if (engineTypes?.length && bodyTypes?.length) {
    const resultsLinks = [];

    engineTypes.map(engineType =>
      bodyTypes.map(bodyType =>
        resultsLinks.push({
          title: `best ${engineType} ${bodyType} rankings`,
          url: `/${bodyType.includes(WAGON) ? WAGON_LOWERCASE : bodyType.toLowerCase()}/${engineType.toLowerCase()}/`,
          selectionType: 'type ranking',
        })
      )
    );

    return resultsLinks;
  }

  return bodyTypes?.map(bodyType => ({
    title: `best ${bodyType} rankings`,
    url: `/${bodyType.includes(WAGON) ? WAGON_LOWERCASE : bodyType.toLowerCase()}/`,
    selectionType: 'type ranking',
  }));
}

export function highlightQuery({ userInput, title }) {
  const escapedUserInput = userInput.replaceAll(/[{}]+|\bor\b|\band\b|\bvs\b/g, '');
  const userInputTokens = escapedUserInput
    .split(' ')
    .map(w => w.trim())
    .filter(token => !!token);
  const highlighted = userInputTokens.reduce(
    (result, item) => result.replaceAll(new RegExp(item, 'gi'), '{{$&}}'),
    title
  );
  const replacerMap = {
    '{': '<span class="font-weight-normal">',
    '}': '</span>',
  };

  return Object.keys(replacerMap).reduce(
    (result, key) => result.replace(new RegExp(`${key}+`, 'g'), replacerMap[key]),
    highlighted
  );
}
