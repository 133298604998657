import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, isEqual, noop } from 'lodash';
import { Button, Form } from 'reactstrap';
/* Constants */
import { TrackingConstant } from 'client/tracking/constant';
import { ENTER_KEY_VALUE } from 'site-modules/shared/constants/key-values';
import {
  NO_FACETS_SCREEN_CREATIVE_ID,
  NO_RESULTS_VIN_CREATIVE_ID,
} from 'site-modules/shared/constants/inventory/llm-suggested-facets';
/* Utils */
import { EventToolbox } from 'client/utils/event-toolbox';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  fireTemporaryClickTracking,
  fireTemporaryKeydownTracking,
} from 'site-modules/shared/utils/inventory/global-search-tracking';
/* Models */
import { buildSearchIdPath, SemanticSearchModel } from 'client/data/models/semantic-search';
/* Components */
import { AutosizedArea } from 'site-modules/shared/components/inventory/autosized-area/autosized-area';
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';

import './semantic-search-form.scss';

export const NEW_SEARCH_PLACEHOLDER = 'Start a new search';
export const TRY_SUV_PLACEHOLDER = 'Try "2023 red SUV with safety features"';
export const LOOKING_FOR_PLACEHOLDER = "Describe what you're looking for";
export const CREATIVE_ID = 'edm-entry-semantic-inventory-search';
export const CREATIVE_ID_STICKY = 'edm-entry-subnav-semantic-inventory-search';
export const ANIMATION_DURATION = 1660;

const INPUT_MIN_HEIGHT = 64;
const INPUT_MIN_HEIGHT_MOBILE = 48;
const BLUR_TRACKING_WAIT_TIME = 1000;
const AUTOSIZED_AREA_LENGTH = 200;

const fireWidgetViewTracking = ({ hintText, isZeroResultScreen, isVinSearch }) => {
  const zeroCreativeId = isVinSearch ? NO_RESULTS_VIN_CREATIVE_ID : NO_FACETS_SCREEN_CREATIVE_ID;
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
    event_data: {
      action_name: TrackingConstant.ACTION_WIDGET_VIEW,
      action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
      total_page_height: Math.max(document.body.scrollHeight, document.body.offsetHeight),
      creative_id: isZeroResultScreen ? zeroCreativeId : CREATIVE_ID,
      value: hintText,
    },
  });
};
const fireFocusTracking = ({ isSticky }) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_START,
    event_data: {
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      subaction_name: TrackingConstant.SELECT_AUTOCOMPLETE,
      creative_id: isSticky ? CREATIVE_ID_STICKY : CREATIVE_ID,
    },
  });
};
const fireBlurTracking = ({ query }) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      subaction_name: TrackingConstant.ABANDON_AUTO_COMPLETE_RESULT,
      input: query,
      creative_id: CREATIVE_ID,
    },
  });
};
const fireSubmitTracking = ({ value, searchId, isSticky }) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      subaction_name: TrackingConstant.VIEW_AUTO_COMPLETE_RESULT,
      creative_id: isSticky ? CREATIVE_ID_STICKY : CREATIVE_ID,
      value: `srp_search_id=${searchId}&search=${value}`,
    },
  });
};

export function SemanticSearchFormUI({
  searchId,
  userSearch,
  YMMTPlaceholder,
  dynamicPlaceholderSuggestions,
  isSticky,
  filtersApplied,
  openLlmDrawer,
  isLlmDrawerOpen,
  isZeroResultScreen,
  isHome,
  isMobile,
  isVinSearch,
  searchFormClassName,
  inputClassName,
  inputMinHeight,
  autosizeOnFocus,
  submitButtonClassName,
  submitButtonContent,
  ariaLabel,
}) {
  const [query, setQuery] = useState(userSearch);
  const [startPlaceholderAnimation, setStartPlaceholderAnimation] = useState(false);
  const [staticPlaceholderPart, setStaticPlaceholderPart] = useState(get(dynamicPlaceholderSuggestions, '[0]', ''));
  const prevDynamicSuggestions = useRef([]);
  const prevYMMTPlaceholder = useRef('');
  const [activePlaceholderId, setActivePlaceholderId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [setCursorTimeout, clearCursorTimeout] = useTimeout();
  const [setPlaceholderChangeTimeout, clearPlaceholderChangeTimeout] = useTimeout();
  const trimmedQuery = query?.trim();
  const submitButtonRef = useRef();
  const scheduledBlurEvent = useRef();
  const isEventAlreadyFired = useRef(false);
  const suggestedFilters = get(dynamicPlaceholderSuggestions, '[0]', '');
  const primingHint = YMMTPlaceholder ? `${YMMTPlaceholder} ${suggestedFilters}`.trim() : suggestedFilters;

  let hintText;

  if (isHome) {
    hintText = LOOKING_FOR_PLACEHOLDER;
  }
  if (isZeroResultScreen) {
    hintText = TRY_SUV_PLACEHOLDER;
  }

  hintText = filtersApplied ? NEW_SEARCH_PLACEHOLDER : hintText;

  useEffect(() => {
    if (isEventAlreadyFired.current) {
      return;
    }

    if (!isHome && !isZeroResultScreen) {
      if (primingHint) {
        fireWidgetViewTracking({ hintText: primingHint, isZeroResultScreen, isVinSearch });
        isEventAlreadyFired.current = true;
      }
    } else {
      fireWidgetViewTracking({ hintText, isZeroResultScreen, isVinSearch });
      isEventAlreadyFired.current = true;
    }
  }, [primingHint, isZeroResultScreen, isVinSearch, isHome, hintText]);

  useEffect(() => {
    if (searchId && isSubmitting) {
      fireSubmitTracking({ value: trimmedQuery, searchId, isSticky });
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, isSubmitting]);

  useEffect(() => {
    if (filtersApplied) {
      setQuery('');
    }
  }, [filtersApplied]);

  useEffect(() => {
    if (userSearch) {
      setQuery(userSearch);
    }
  }, [userSearch]);

  useEffect(() => {
    // needed after filters are changed to make sure user won't see YMMT + 'Describe...' for the case when YMMT appears after being ''
    if (YMMTPlaceholder !== prevYMMTPlaceholder.current) {
      prevYMMTPlaceholder.current = YMMTPlaceholder;
      setStaticPlaceholderPart('');
    }
  }, [YMMTPlaceholder]);

  useEffect(() => {
    if (filtersApplied) {
      return noop;
    }

    function changePlaceholder(id, stopAnimation) {
      setStartPlaceholderAnimation(true);
      setActivePlaceholderId(id);

      // needed to change staticPlaceholderPart to the value that should appear after animation
      // check for id is needed to be sure that animation is in progress, and we don't need the value that was shown before animation
      if (id === 1) {
        setStaticPlaceholderPart(YMMTPlaceholder ? '' : LOOKING_FOR_PLACEHOLDER);
      }

      if (stopAnimation) {
        setStartPlaceholderAnimation(false);
        return;
      }

      setPlaceholderChangeTimeout(() => {
        changePlaceholder(
          (id + 1) % dynamicPlaceholderSuggestions.length,
          id + 1 === dynamicPlaceholderSuggestions.length
        );
      }, ANIMATION_DURATION);
    }

    // needed after filters are changed to reset staticPlaceholderPart to the value that should be shown before animation starts
    // check for prevValue is needed to make sure we apply placeholder when suggestions are already updated
    if (!isEqual(dynamicPlaceholderSuggestions, prevDynamicSuggestions.current)) {
      prevDynamicSuggestions.current = dynamicPlaceholderSuggestions;
      setStaticPlaceholderPart(get(dynamicPlaceholderSuggestions, '[0]', ''));
    }

    changePlaceholder(0);

    return clearPlaceholderChangeTimeout;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicPlaceholderSuggestions, filtersApplied]);

  const clearScheduledBlurTracking = useCallback(() => {
    if (scheduledBlurEvent.current) {
      clearTimeout(scheduledBlurEvent.current);
    }
  }, []);

  const handleSubmit = useCallback(
    async (event, isKeyboardSubmit = false) => {
      event.preventDefault();
      clearScheduledBlurTracking();

      if (trimmedQuery && trimmedQuery !== YMMTPlaceholder) {
        setIsSubmitting(true);
        await openLlmDrawer(trimmedQuery);

        if (isHome) {
          if (isKeyboardSubmit) {
            fireTemporaryKeydownTracking(event);
          } else {
            fireTemporaryClickTracking();
          }
        }
      }
    },
    [clearScheduledBlurTracking, trimmedQuery, YMMTPlaceholder, openLlmDrawer, isHome]
  );

  const handleKeyDown = useCallback(
    async event => {
      if (event.key === ENTER_KEY_VALUE) {
        event.preventDefault();
        await handleSubmit(event, true);
      }
    },
    [handleSubmit]
  );

  const handleFocus = useCallback(
    e => {
      fireFocusTracking({ isSticky });
      if (
        !trimmedQuery &&
        !filtersApplied &&
        YMMTPlaceholder.length &&
        YMMTPlaceholder.length < AUTOSIZED_AREA_LENGTH
      ) {
        setQuery(`${YMMTPlaceholder} `);
        setCursorTimeout(() => {
          e.target.selectionStart = YMMTPlaceholder.length + 1;
          e.target.selectionEnd = YMMTPlaceholder.length + 1;
          clearCursorTimeout();
        }, 0);
      }
    },
    [isSticky, trimmedQuery, filtersApplied, YMMTPlaceholder, setCursorTimeout, clearCursorTimeout]
  );

  const scheduleBlurTracking = useCallback(() => {
    clearScheduledBlurTracking();
    scheduledBlurEvent.current = setTimeout(() => {
      if (!isLlmDrawerOpen) {
        fireBlurTracking({ query });
      }
    }, BLUR_TRACKING_WAIT_TIME);
  }, [clearScheduledBlurTracking, isLlmDrawerOpen, query]);

  const handleBlur = useCallback(() => {
    if (query && trimmedQuery === YMMTPlaceholder) {
      setQuery('');
    }
    if (query && trimmedQuery !== YMMTPlaceholder) {
      scheduleBlurTracking();
    }
  }, [YMMTPlaceholder, query, scheduleBlurTracking, trimmedQuery]);
  const disableSubmit = isZeroResultScreen && trimmedQuery === userSearch;

  return (
    <Form
      id="srp-query-form"
      noValidate
      className={searchFormClassName}
      style={autosizeOnFocus ? { minHeight: `${isMobile ? INPUT_MIN_HEIGHT_MOBILE : INPUT_MIN_HEIGHT}px` } : {}}
    >
      {!filtersApplied && !query && (
        <div
          className="animated-placeholder pos-a pl-0_5 pb-0_75 w-100 size-16 text-cool-gray-50"
          style={{
            paddingRight: '44px',
            paddingTop: '9px',
            border: '1px solid transparent',
            pointerEvents: 'none',
            height: `${inputMinHeight}px`,
            overflow: 'hidden',
          }}
        >
          <span className="sr-only">Example Search:</span>
          {YMMTPlaceholder}
          {YMMTPlaceholder ? ' ' : ''}
          {dynamicPlaceholderSuggestions.map((filter, id) => {
            const key = `suggested-option-${id}`;
            if (id) {
              return (
                <span
                  key={key}
                  className={classnames('changing-part', {
                    animate: id === activePlaceholderId,
                  })}
                >
                  {id === activePlaceholderId ? filter : ''}
                </span>
              );
            }

            return null;
          })}
          <span key="suggested-option-0" className={classnames('static-part', { hidden: startPlaceholderAnimation })}>
            {staticPlaceholderPart}
          </span>
        </div>
      )}
      <div className={classnames({ 'pos-a w-100': autosizeOnFocus })}>
        <AutosizedArea
          onChange={setQuery}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          hintText={hintText}
          inputValue={query}
          inputMinHeight={inputMinHeight}
          maxLength={AUTOSIZED_AREA_LENGTH}
          className={inputClassName}
          autosizeOnFocus={autosizeOnFocus}
          aria-label={ariaLabel}
        />
        <Button
          color="outline"
          size="sm"
          type="submit"
          onClick={handleSubmit}
          className={classnames(submitButtonClassName, { 'autosized-on-focus': autosizeOnFocus })}
          aria-label="Search"
          disabled={!trimmedQuery || trimmedQuery === YMMTPlaceholder || disableSubmit}
          ref={submitButtonRef}
        >
          {submitButtonContent}
        </Button>
      </div>
    </Form>
  );
}

SemanticSearchFormUI.propTypes = {
  searchId: PropTypes.string,
  userSearch: PropTypes.string,
  YMMTPlaceholder: PropTypes.string,
  dynamicPlaceholderSuggestions: PropTypes.arrayOf(PropTypes.string),
  isSticky: PropTypes.bool,
  filtersApplied: PropTypes.bool,
  isLlmDrawerOpen: PropTypes.bool,
  openLlmDrawer: PropTypes.func,
  isZeroResultScreen: PropTypes.bool,
  isHome: PropTypes.bool,
  isMobile: PropTypes.bool,
  isVinSearch: PropTypes.bool,
  searchFormClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  inputMinHeight: PropTypes.number,
  autosizeOnFocus: PropTypes.bool,
  submitButtonClassName: PropTypes.string,
  submitButtonContent: PropTypes.node,
  ariaLabel: PropTypes.string,
};

SemanticSearchFormUI.defaultProps = {
  searchId: '',
  userSearch: '',
  YMMTPlaceholder: '',
  dynamicPlaceholderSuggestions: [],
  isSticky: false,
  filtersApplied: false,
  isLlmDrawerOpen: false,
  openLlmDrawer: noop,
  isZeroResultScreen: false,
  isHome: false,
  isMobile: false,
  isVinSearch: false,
  submitButtonContent: <span className="icon-arrow-right8 icon text-white size-16" aria-hidden />,
  searchFormClassName: 'srp-query-input-form pos-r mb-1_25 mb-md-0 py-md-0_5',
  inputClassName: 'srp-query-input-control w-100 size-16 pt-0_75 pl-0_5',
  inputMinHeight: INPUT_MIN_HEIGHT_MOBILE,
  autosizeOnFocus: undefined,
  submitButtonClassName:
    'srp-query-input-submit-btn pos-a d-flex justify-content-center align-items-center bg-green-50',
  ariaLabel: 'Search:',
};

export const stateToPropsConfig = {
  searchId: bindToPath(({ searchRoute }) => buildSearchIdPath(searchRoute), SemanticSearchModel),
  userSearch: bindToPath('userSearch', SemanticSearchModel),
};

export const SemanticSearchForm = connectToModel(SemanticSearchFormUI, stateToPropsConfig);
