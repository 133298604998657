import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI, json } from 'client/data/api/api-client';
import { get } from 'lodash';
import { getRadiusByDmaRank, InventoryModel } from 'client/data/models/inventory';
import { HTTP_ACCEPTED } from 'client/utils/http-status';
import { withRetry } from 'client/utils/promise';
import { PageModel } from 'client/data/models/page';
import { getDealerRooftopId } from 'site-modules/shared/utils/dealer';
import { VisitorModel } from './visitor';

export const LLM_TIMEOUT = 10000;
export const LLM_GET_RETRIES_COUNT = 2;

export function buildSearchIdPath(route) {
  return `routes["${route || 'srp_search'}"].searchId`;
}

export function getExtraFacetsPath() {
  return 'extraFacets';
}

const withRetry202Response = (promiseCall, retries, delayMS) => {
  // Rejects promise when 202 response received to let withRetry helper trigger retry.
  const reject202Response = response => {
    if (response.status === HTTP_ACCEPTED) {
      return Promise.reject({});
    }

    return response;
  };

  return withRetry((...params) => promiseCall(...params).then(reject202Response), retries, delayMS);
};

export const SemanticSearchModel = createModelSegment('semanticSearch', [
  {
    path: 'userSearch',
  },
  /**
   * @see getExtraFacetsPath
   */
  {
    path: 'extraFacets',
    async resolve(match, context) {
      const pageFilters = await context.resolveValue('searchResultsFilter', InventoryModel);

      return Promise.resolve({ inventoryType: pageFilters?.inventoryType });
    },
  },
  /**
   * @see buildSearchIdPath
   */
  {
    path: 'routes["{route}"].searchId',
    async resolve({ route }, context) {
      const [userSearch, location, { zipCode, dmaRank }, extraFacets] = await Promise.all([
        context.resolveValue('userSearch'),
        context.resolveValue('location', PageModel, false),
        context.resolveValue('location', VisitorModel, false),
        context.resolveValue(getExtraFacetsPath(), SemanticSearchModel, false),
      ]);

      if (!userSearch) return '';

      const radius = getRadiusByDmaRank(dmaRank);
      const dealerId = getDealerRooftopId({ location });

      const request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: userSearch,
          extra: {
            facets: {
              zip: zipCode,
              radius,
              dealerId,
              ...extraFacets,
            },
          },
        }),
        timeout: LLM_TIMEOUT,
      };

      try {
        const searchData = await EdmundsAPI.fetchJson(`/llm/v1/ask/${route}/openai-4o-mini/`, request);
        return get(searchData, 'id', '');
      } catch (e) {
        return null;
      }
    },
  },
  {
    path: 'suggestedFilters["{searchId}"]',
    async resolve({ searchId }) {
      try {
        const fetchWithoutBuiltinRetries = async (resourcePath, options) =>
          // We pass retries: 0 here for disabling default retry logic, provided by api-client, because of explicit usage of withRetry below
          EdmundsAPI.fetch(resourcePath, { ...options, retries: 0 });

        const fetchWithCustomRetry = withRetry202Response(fetchWithoutBuiltinRetries, LLM_GET_RETRIES_COUNT);
        const searchDataResponse = await fetchWithCustomRetry(`/llm/v1/get/${searchId}`, { timeout: LLM_TIMEOUT });
        const searchData = await json(searchDataResponse);
        return {
          facetsByName: get(searchData, 'struct.facetsByName', []),
          inventoryCount: get(searchData, 'struct.inventoryCount', 0),
          modifiedRadius: get(searchData, 'struct.modifiedFacets.radius'),
          identifiedVin: get(searchData, 'struct.identifiedVin'),
        };
      } catch (e) {
        return null;
      }
    },
  },
]);
